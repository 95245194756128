import React, { useContext } from 'react';

import Image from 'next/image';
import s from './OfferSection.module.scss';
import classNames from 'classnames';
import ButtonSecondary from '../../components/ButtonSecondary';
import GradientBlurb from '../../components/GradientBlurb';
import PhotoCred from '../PhotoCred';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import Sticky from 'react-stickynode';
import VideoIcon from '../VideoIcon';
import { GradientContext, BackgroundContext } from '../../containers/BasePage';
import { trackReadMore, trackContactLead } from '../../utils/dataLayer';

const THEME_PINK_PURPLE = 'PinkPurple';
const THEME_PINK_ORANGE = 'PinkOrange';
const THEME_PINK_PURPLE_BLUE = 'PinkBlue';
const THEME_BLUE_PURPLE = 'PurpleBlue';
const THEME_BLUE_GREEN = 'BlueGreen';

const iconMap = {
  [THEME_BLUE_PURPLE]: [
    {
      src: '/video/crown-reveal-hevc/crown--blue-purple--350p--hevc.mp4',
      type: 'video/mp4; codecs="hvc1"',
    },
    {
      src: '/video/crown-reveal-vp9/crown--blue-purple--350p--vp9.webm',
      type: 'video/webm',
    },
  ],
  [THEME_PINK_PURPLE]: [
    {
      src: '/video/crown-reveal-hevc/crown--pink-purple--350p--hevc.mp4',
      type: 'video/mp4; codecs="hvc1"',
    },
    {
      src: '/video/crown-reveal-vp9/crown--pink-purple--350p--vp9.webm',
      type: 'video/webm',
    },
  ],
  [THEME_PINK_PURPLE_BLUE]: [
    {
      src: '/video/crown-reveal-hevc/crown--pink-purple-blue--350p--hevc.mp4',
      type: 'video/mp4; codecs="hvc1"',
    },
    {
      src: '/video/crown-reveal-vp9/crown--pink-purple-blue--350p--vp9.webm',
      type: 'video/webm',
    },
  ],
  [THEME_PINK_ORANGE]: [
    {
      src: '/video/crown-reveal-hevc/crown--pink-orange--350p--hevc.mp4',
      type: 'video/mp4; codecs="hvc1"',
    },
    {
      src: '/video/crown-reveal-vp9/crown--pink-orange--350p--vp9.webm',
      type: 'video/webm',
    },
  ],
  [THEME_BLUE_PURPLE]: [
    {
      src: '/video/crown-reveal-hevc/crown--blue-purple--350p--hevc.mp4',
      type: 'video/mp4; codecs="hvc1"',
    },
    {
      src: '/video/crown-reveal-vp9/crown--blue-purple--350p--vp9.webm',
      type: 'video/webm',
    },
  ],
  [THEME_BLUE_GREEN]: [
    {
      src: '/video/crown-reveal-hevc/crown--blue-green--350p--hevc.mp4',
      type: 'video/mp4; codecs="hvc1"',
    },
    {
      src: '/video/crown-reveal-vp9/crown--blue-green--350p--vp9.webm',
      type: 'video/webm',
    },
  ],
};

const OfferSection = ({ cards = [], anchorLink, ctaText }) => {
  const [inViewRef, isVisible] = useIntersectionObserver(
    undefined,
    undefined,
    true
  );
  const [inViewRefCta, isCtaVisible] = useIntersectionObserver(
    undefined,
    undefined,
    true
  );
  const background = useContext(BackgroundContext);

  const gradient = useContext(GradientContext);
  const classes = classNames(
    s.Root,
    s[gradient],
    background?.whiteBackground ? s.WhiteBackground : s.BlackBackground
  );
  const { t } = useTranslation();

  let iconSrcs = iconMap?.[gradient];

  return (
    <section className={classes} id={anchorLink}>
      {!!cards.length && (
        <div className={s.GutterContainer} id="offer-gutter">
          <Sticky top={16} bottomBoundary="#offer-gutter">
            <h2 className={s.GutterTitle}>How we can help</h2>
          </Sticky>
        </div>
      )}
      {!!cards.length &&
        cards.map((card, i) => (
          <OfferCard
            {...card}
            key={i}
            gradient={gradient}
            isReversed={i % 2 !== 0}
          />
        ))}
      {ctaText && (
        <div
          ref={inViewRefCta}
          className={classNames(s.CtaWrap, 'grid', {
            [s.CtaVisible]: isCtaVisible,
          })}>
          <div className={`${s.CtaBorder}`}>
            <div className={`${s.Cta}`} ref={inViewRef}>
              {iconSrcs && (
                <div className={s.CtaImageWrap}>
                  <VideoIcon
                    className={s.VideoIcon}
                    active={isVisible}
                    transitionDelay={500}
                    srcs={iconSrcs}
                    ignoreAnimationsDisabled
                  />
                </div>
              )}
              <h3 className={s.CtaText}>{ctaText}</h3>
              <ButtonSecondary
                modifiers={['InlineFlex', 'IconHoverDown', 'IconRotate45']}
                onClick={() => {
                  trackContactLead('Offer Section CTA');
                  document.getElementById('footer-contact').scrollIntoView({
                    behavior: 'smooth',
                  });
                }}
                icon="icon-arrow-right-white">
                {t('Generic.contact')}
              </ButtonSecondary>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

const OfferCard = ({ image, title, text, href, isReversed, icon }) => {
  const { t } = useTranslation();
  const [ref, isVisible] = useIntersectionObserver();
  const background = useContext(BackgroundContext);

  const classes = classNames(s.OfferCard, {
    grid: true,
    [s.Reverse]: isReversed,
    [s.Visible]: isVisible,
    [s.HasIcon]: !!icon,
  });

  const icons = {
    megaphone: {
      url: '/img/img-megaphone-red.png',
    },
  };

  const imageContainerClasses = classNames(s.ImageContainer, {
    [s.HasIcon]: !!icon,
  });

  const imageStyle = { objectFit: 'cover' };

  if (!!image && !!image.focal && !!image.focal.x && !!image.focal.y) {
    imageStyle.objectPosition = `${image.focal.x} ${image.focal.y}`;
  }

  const handleTrackReadMore = (e) => {
    e.preventDefault();

    trackReadMore(title, 'Offer section');

    setTimeout(() => {
      window.location.href = href?.href;
    }, 200);
  };

  return (
    <div className={classes} ref={ref}>
      <div className={imageContainerClasses}>
        <GradientBlurb isVisible={isVisible} isReversed={isReversed} />
        {!!icon ? (
          <Image
            className={s.Icon}
            src={icons[icon].url}
            alt=""
            fill
            style={{ objectFit: 'contain', right: 0 }}
          />
        ) : (
          <Image
            className={s.Image}
            src={image?.url}
            alt=""
            fill
            style={imageStyle}
          />
        )}
        <PhotoCred credit={image?.credit} modifiers={['BottomMargin']} />
      </div>
      <div className={s.CardContent}>
        <h3 className={s.Heading}>{title}</h3>
        <div className={s.TextAndButton}>
          <p className={s.Text}>{text}</p>
          <div className={s.Button}>
            {!!href?.href && (
              <Link href={href.href} legacyBehavior>
                <a onClick={handleTrackReadMore} className={s.Link}>
                  <Image
                    width={19}
                    height={16}
                    src={
                      background?.whiteBackground
                        ? `/img/icon-arrow-right-black.svg`
                        : `/img/icon-arrow-right-white.svg`
                    }
                    alt=""
                    className={s.Icon}
                  />
                  {t('Generic.readMore')}
                  <span className="sr-only">{' ' + title}</span>
                </a>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

OfferSection.propTypes = {};

OfferSection.defaultProps = {};

export default OfferSection;
