import React, { useContext, useRef, useEffect } from 'react';

// import i18n from '../../i18n';
// import PropTypes from 'prop-types';
import classNames from 'classnames';
import s from './HeroBackground.module.scss';
import {
  AnimationsActiveContext,
  GradientContext,
} from '../../containers/BasePage';

const HeroBackground = ({ modifiers = [] }) => {
  const { animationsActive } = useContext(AnimationsActiveContext) || {};
  const gradient = useContext(GradientContext);
  const videoRef = useRef(null);
  const desktopVideoRef = useRef(null);

  useEffect(() => {
    if (animationsActive) {
      if (videoRef.current) {
        videoRef.current.play();
      }

      if (desktopVideoRef.current) {
        desktopVideoRef.current.play();
      }
    } else {
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0.3;
      }

      if (desktopVideoRef.current) {
        desktopVideoRef.current.pause();
        desktopVideoRef.current.currentTime = 0.3;
      }
    }
  }, [animationsActive]);

  const classes = classNames(
    s.Root,
    'grid',
    s[gradient],
    modifiers.map((m) => s[m])
  );

  return (
    <section className={classes} aria-hidden={true}>
      {/*<div className={s.BlurbContainer}>
        <div className={s.Blurbs} />
      </div>*/}
      <video
        data-matomo-ignore
        className={s.PlattanMobile}
        preload="auto"
        playsInline
        ref={videoRef}
        autoPlay={animationsActive}
        muted
        loop>
        <source
          src={`/video/bg/${gradient}Mobile.mp4`}
          type={`video/mp4; codecs="hvc1"`}
        />
        <source src={`/video/bg/${gradient}Mobile.webm`} type="video/webm" />
        <track kind="subtitles" src="/video/no-audio.vtt" srclang="en" />
      </video>
      <video
        data-matomo-ignore
        className={s.PlattanDesktop}
        preload="auto"
        playsInline
        autoPlay={animationsActive}
        ref={desktopVideoRef}
        muted
        loop>
        <source
          src={`/video/bg/${gradient}Desktop.mp4`}
          type={`video/mp4; codecs="hvc1"`}
        />
        <source src={`/video/bg/${gradient}Desktop.webm`} type="video/webm" />
        <track kind="subtitles" src="/video/no-audio.vtt" srclang="en" />
      </video>
    </section>
  );
};

HeroBackground.propTypes = {};

HeroBackground.defaultProps = {};

export default HeroBackground;
